@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,400i);
@import url(https://fast.fonts.net/cssapi/36b8d123-bdd0-4ed7-a54d-0e0c8d414ac2.css);

body {
    background-color: $clr-neutral-x2light;
    font-weight: $font-weight-base;
    padding-top: 48px;
    text-rendering: optimizeLegibility;
    //text-align: center;
}

.container {
    max-width: 900px !important;
    margin: 0 auto;
}

.body {
    @include clearfix;
}

.main {
    padding-bottom: 5rem;
}
.tax-scripture .main,
.post-type-archive-aslv-passage .main
{
    padding-bottom: 0;
}