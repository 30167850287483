.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 99;
    background-color: $clr-neutral-x2light;
    @include box-shadow(0 0 10px rgba(0, 0, 0, .5));
}

.brand {
    display: block;
    height: 48px;
    background: transparent url('../../assets/images/brand-mark.svg') no-repeat left center;
    background-size: auto 32px;
    text-indent: -999rem;
}

/*
.nav-primary {
    display: inline-block;
}
#menu-primary {
    display: inline-block;
}
*/

.btn-give {
    position: absolute;
    top: 13px;
    right: 52px;
    padding-top: 3px;
    line-height: 1.5;
    font-size: 1.25rem;
    padding-left: 1em;
    padding-right: 1em;
    letter-spacing: .1em;
}

.nav-primary {

    min-height: unset;
    margin-top: 0;
    margin-bottom: 0;
    border: none;

    .navbar-toggler {
        position: absolute;
        top: -48px;
        right: -2px;
        border: none;
        background-color: $clr-neutral-x2light;
        color: $clr-primary;
        font-size: 32px;
        padding: 0;
    }

    .navbar-toggleable-xs {
        margin-left: -15px;
    }

    .navbar-nav {
        margin: 0;
    }

    .menu-item {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        a {
            box-sizing: content-box;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            color: $clr-neutral-x2light;
            font-weight: 100;
            background-color: $clr-primary;
            opacity: .93;
            border-top: 1px solid $clr-primary-dark;
            &:hover {
                background-color: $clr-neutral-x2light;
                color: $clr-primary;
            }
        }
    }

}

@media (min-width: $screen-sm-min) {

    .banner .container {
        position: relative;
    }

    .btn-give {
        right: 15px;
    }

    .navbar-toggler {
        display: none;
    }

    .nav-primary {

        position: absolute;
        top: 0;
        right: 9rem;
        display: block;

        .menu-item {
            display: inline-block;
            a {
                background-color: transparent;
                color: $clr-secondary;
                border-top: none;
                font-size: 1.5rem;
                //padding-top: 11px;
                &:hover {
                    background-color: transparent;
                }
            }
            &.active > a {
                color: $clr-primary;
            }
        }

    }

    .navbar-toggleable-xs {
        display: block;
    }





}