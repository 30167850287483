.resources {
    @include clearfix;
}

.resources-title {
    font-weight: 200;
    font-size: 2.5rem;
    border-bottom: 1px solid $clr-primary;
    display: inline-block;
    color: $clr-primary;
    font-family: $font-family-sans-serif;
}

.resources-list {
    position: relative;
    min-height: 150px;
}

.resources-list-items-container {
    position: relative;
    overflow: hidden;
    min-height: 150px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.resources-list-items {
    position: absolute;
    left: 0;
    top: 0;
    list-style: none inside none;
    padding-left: 0;
    white-space: nowrap;
    transition: left .5s ease-in-out;
}

.resources-list-item {

    display: inline-block;
    //float: left;
    margin-right: 23px;
    width: 200px;

    a {

        display: block;

        img {
            width: 100%;
            max-height: 112px;
        }

        &:hover,
        &:active
        {

            text-decoration: none;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                z-index: 5;
                top: 0;
                left: 0;
                width: 100%;
                height: 112px;
                background: rgba($clr-primary, .75) none no-repeat center center;
                background-size: auto 50%;
            }

        }
    }

    span {
        display: block;
        color: $clr-secondary;
        font-family: $font-family-sans-serif;
        font-size: smaller;
        padding-top: .5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:nth-child(4n+4) {
        margin-right: 0;
    }

    &.resource-list-item-video a {
        &:hover,
        &:active
        {
            &:after {
                background-image: url('../images/icn-play.svg');
            }
        }
    }

    &.resource-list-item-download a {
        &:hover,
        &:active
        {
            &:after {
                background-image: url('../images/icn-download.svg');
            }
        }
    }

}

.resources-list-nav {
    padding-left: 0;
    margin-bottom: 0;
}
.resources-list-nav-prev,
.resources-list-nav-next
{
    position: absolute;
    z-index: 20;
    top: 28px;
    height: 56px;
    width: 56px;
    a {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50% 50%;
        background: $clr-primary none no-repeat center center;
        background-size: auto 50%;
        text-indent: -999rem;
    }
}
.resources-list-nav-prev {
    left: -28px;
    a {
        background-image: url('../images/icn-previous.svg');
    }
}
.resources-list-nav-next {
    right: -28px;
    a {
        background-image: url('../images/icn-next.svg');
    }
}

.loading {
    .resources-list-nav {
        display: none;
    }
    .resources-list-items-container:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 30;
        background: rgba(255,255,255,.75);
    }
}

.resource-downloads {
    padding-left: 0;
    list-style: none inside;
    .resource-download {
        display: inline-block;
        margin-right: 2rem;
        .btn-primary {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            text-transform: none;
        }
        p {
            text-align: center;
            font-size: 1.25rem;
            margin-top: .5rem;
        }
    }
}