.post-loading {
    text-align: center;
    color: #999;
    font-size: 3rem;
    padding: 3rem;
    line-height: 20;
}

.post-tags {
    border-top: 1px solid $clr-secondary;
    border-bottom: 1px solid $clr-secondary;
    padding-top: 1rem;
    padding-bottom: 1rem;
    list-style: none inside;
    padding-left: 0;
    text-align: center;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.post-tag {

    display: inline-block;
    margin-left: .25rem;
    margin-right: .25rem;

    a {

        font-family: $font-family-sans-serif;
        font-size: $font-size_small;
        display: block;
        line-height: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid $clr-secondary;
        color: $clr-secondary;
        border-radius: 1.5rem;

        &:hover,
        &:active{
            color: $clr_neutral-x2light;
            background-color: $clr_secondary;
            text-decoration: none;
        }

    }
}