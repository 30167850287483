.modal {

    .container {
        width: 100% !important;
    }

    .modal-header {
        border-bottom: none;
    }

    .video-js {
        width: 100%;
        height: auto;
    }

    .modal-content {
        border-radius: 0;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .podcast_player {

        top: 5rem;
        padding-top: 0;
        margin-top: 0;

        .wp-video {
            video {
                height: 125%;
            }
        }

    }

    .close {
        position: relative;
        left: 3rem;
        color: $clr_primary;
        font-family: $font-family-sans-serif;
        font-weight: 100;
        font-size: 4rem;
        opacity: 1;
    }

    .post-meta {
        top: 0;
        margin-top: 0;
    }

    .modal-title {
        position: relative;
        z-index: 5;
        margin-top: 70%;
        text-align: center;
        font-family: $font-family-sans-serif;
        color: $clr-primary;
        font-weight: 200;
        line-height: 1;
    }

    .modal-body {
        padding: 0;
        position: static;
    }

    .modal-footer {
        border-top: none;
        text-align: left;
        padding-top: 1.5rem;
        padding-left: 0;
        padding-right: 0;
    }

}

@media (max-width: 600px) {

    .modal {

        .modal-title {
            margin-top: 75%;
        }

    }

}

@media (max-width: 400px) {

    .modal {

        .modal-title {
            margin-top: 80%;
        }

    }

}