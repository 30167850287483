// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors

$clr-primary:           #ef4036;
$clr-primary-dark:      #c33e37;

$clr-secondary-x2light: #e9f7f9;
$clr-secondary-xlight:  #91b4b9;
$clr-secondary-light:   #90b3b8;
$clr-secondary:         #7ea7ad;
$clr-secondary-dark:    #8db0b5;
$clr-secondary-xdark:   #313d4b;

$clr-neutral-x2light:    #fff;
$clr-neutral-medium:    #5d676f;
$clr-neutral-dark:      #414141;
$clr-neutral-xdark:     #2f3337;
$clr-neutral-x2dark:    #262626;
$clr-neutral-x3dark:    #1f1f1f;
$clr-neutral-x4dark:    #000;


// Fonts
$font-family-sans-serif:    'Proxima Nova W01', sans-serif;
$font-family-serif:         'Crimson Text', serif;
$font-family-monospace:     Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:          $font-family-serif;
$font-size-base:            17px;
$font-weight-base:          300;
$text-color:                $clr-neutral-dark;