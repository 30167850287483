.home-nav {
    position: absolute;
    width: 100%;
    max-width: 900px;
    padding-top: 15px;
    padding-bottom: 15px;
    top: 35rem;
    left: 0;
    background-color: $clr-neutral-dark;
    opacity: .93;
    text-align: center;
    font-family: $font-family-sans-serif;
}
.home-nav-items {
    padding-left: 0;
    margin: 0;
}
.home-nav-item {

    display: inline-block;
    margin-left: 2rem;
    margin-right: 2rem;

    a {

        display: block;
        width: 100%;
        position: relative;
        color: $clr-neutral-x2light;
        font-weight: 100;
        font-size: 1.75rem;

        img {
            position: absolute;
            top: 10px;
            left: 50%;
            margin-left: -20px;
            width: 40px;
            height: 40px;
        }

        &:before {
            content: "";
            display: block;
            border-radius: 50%;
            background: $clr-primary none no-repeat center center;
            background-size: 50% 50%;
            width: 60px;
            height: 60px;
            margin: 0 auto;
            margin-bottom: 1rem;
        }

        &:hover {
            text-decoration: none;
            color: $clr-primary;
        }

    }

}

.home-latest {
    max-width: 720px !important;
    margin: 0 auto;
}
.home-latest-title {
    position: relative;
    text-align: center;
    span {
        position: relative;
        z-index: 1;
        background-color: $clr-neutral-x2light;
        padding-left: 1rem;
        padding-right: 1.25rem;
        font-size: 2.5rem;
        color: $clr-primary;
        font-style: italic;
        font-weight: 100;
    }
    &:before {
        content: "";
        background-color: $clr-secondary;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 60%;
        z-index: 0;
    }
}
.home-latest-items {
    padding-left: 0;
}
.home-latest-item {

    max-width: 240px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 5rem;

    a {
        display: block;
        color: $clr-neutral-dark;
        &:hover {
            text-decoration: none;
        }
    }

    .post-img {
        display: block;
        width: 100%;
        height: 140px;
        overflow: hidden;
        text-align: center;
        img {
            margin: 0 auto;
            height: 100%;
        }
    }

    .post-title {
        font-family: $font-family-sans-serif;
        color: $clr-primary;
        font-size: $font-size-base;
        font-weight: 100;
        margin-bottom: 0;
        margin-top: 1rem;
        margin-bottom: .25rem;
    }
    .post-meta {
        color: $clr-secondary-light;
        margin-top: 0;
        font-style: italic;
        line-height: 1;
        height: 3rem;
    }
    .post-exerpt {
        text-align: left;
    }

}

@media (min-width: $screen-sm-min) {

    .home-nav-items {
        margin: 0 auto;
        width: 720px;
    }

    .home-nav-item {

        text-align: left;
        //width: 33.33%;
        height: 60px;
        overflow: hidden;

        a {

            line-height: 60px;

            &:before {
                float: left;
                margin-right: 1rem;
            }

            img {
                left: 10px;
                margin-left: 0;
            }

        }

    }

    .home-latest-item {
        float: left;
        a {
            text-decoration: none;
            &:hover,
            &:active
            {
                text-decoration: none;
            }
        }
    }

}

@media (min-width: 900px) {

    .home-nav {
        left: 50%;
        margin-left: -450px;
    }

}