.aslv-passages {
    background-color: #dee8ea;
    font-family: $font-family-sans-serif;
    text-align: center;
    padding-bottom: 4rem;
}

.aslv-testaments {
    margin: 0 auto;
    max-width: 900px;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.aslv-testament {
    display: inline-block;
    margin-left: .5rem;
    margin-right: .5rem;
    a {
        display: block;
        line-height: 2;
        font-size: 2rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        color: $clr-secondary;
        font-size: smaller;
    }
    &.active a,
    &:hover a
    {
        background: white;
        border-radius: 3rem;
        text-decoration: none;
    }
}

.aslv-books {
    border-top: 1px solid $clr-secondary;
    margin: 0 auto;
    max-width: 600px;
    list-style: none inside;
    padding-left: 100px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: left;
}
.aslv-book {
    width: 33.33%;
    a {
        color: $clr-secondary;
        font-size: smaller;
    }
    &.aslv-book-empty {
        opacity: .5;
    }
}
@media (max-width: 520px) {
    .aslv-books {
        padding-left: 0;
    }
    .aslv-book {
        width: auto !important;
        margin-top: 0 !important;
        left: 0 !important;
        text-align: center;
    }
}

.aslv-books-old-testament li:nth-child(14),
.aslv-books-old-testament li:nth-child(27)
{
    margin-top: -31.2rem;
}
.aslv-books-old-testament li:nth-child(n+14):nth-child(-n+26) {
    position: relative;
    left: 33.33%;
}
.aslv-books-old-testament li:nth-child(n+27):nth-child(-n+39) {
    position: relative;
    left: 66.66%;
}

.aslv-books-new-testament li:nth-child(10),
.aslv-books-new-testament li:nth-child(19)
{
    margin-top: -21.6rem;
}
.aslv-books-new-testament li:nth-child(n+10):nth-child(-n+18) {
    position: relative;
    left: 33.33%;
}
.aslv-books-new-testament li:nth-child(n+19):nth-child(-n+27) {
    position: relative;
    left: 66.66%;
}

.aslv-book-link {
    border-top: 1px solid $clr-secondary;
    margin: 0 auto;
    max-width: 600px;
    display: block;
    padding-top: 1.5rem;
    padding-bottom: .5rem;
    font-size: smaller;
    color: $clr-secondary;
}

.aslv-chapters {
    padding-left: 0;
    padding-bottom: 1rem;
    list-style: none inside;
    margin: 0 auto;
    max-width: 600px;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}
.aslv-chapter {
    float: left;
    width: 10%;
    text-align: center;
    padding: .5rem;
    a {
        display: block;
        color: $clr-secondary;
        font-size: medium;
        line-height: 2;
    }
    &.active a,
    a:hover
    {
        background-color: white;
        border-radius: 3rem;
    }
}
@media (max-width: 480px) {
    .aslv-chapter {
        width: 20%;
    }
}

.aslv-passage {
    .featured-video-plus {
        background: black;
        padding: 15px;
    }
    .wp-video {
        margin: 0 auto;
        max-width: 600px;
    }
}
.aslv-passage-title {
    margin: 0 auto;
    max-width: 900px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -9rem;
    height: 9rem;
    color: white;
    font-family: $font-family-sans-serif;
    font-weight: 200;
    font-size: large;
    line-height: 9rem;
    span {
        font-weight: 400;
    }
}