@media (min-width: 480px) {
    .page-header-wrapper {
        position: relative;
        .featured-video-plus {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

.page-header {
    position: relative;
    z-index: 2;
    border-bottom: none;
    text-align: center;
    color: $clr-primary;
    font-family: $font-family-sans-serif;
}

.page-title {
    font-weight: 200;
}

.has-featured-img {

    background: transparent none no-repeat center top;
    background-size: auto 520px;

    .page-header-wrapper {
        height: 520px;
        padding-top: 34.5rem;
    }

    .page-header {
        background-color: $clr-primary;
        color: $clr-neutral-x2light;
        font-family: $font-family-sans-serif;
        opacity: .93;
        margin: 0;
        padding: 2rem;
        text-align: left;
    }

    .page-title {
        font-weight: 100;
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 0;
        font-size: x-large;
    }

    .page-tagline {
        padding-top: 1rem;
        padding-bottom: 0;
        font-size: 1.75rem;
        font-weight: 100;
        margin: 0 auto;
        font-size: smaller;
    }

}

@media (min-width: 560px) {

    .has-featured-img {

        .page-header {
            position: relative;
            max-width: 340px;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

    }

}

.home {

    .has-featured-img {

        .page-header-wrapper {
            padding-top: 11.5rem;
        }

        .page-header {

            margin: 0 auto;
            max-width: 340px;
            background-color: $clr-secondary;
            color: $clr-neutral-x2light;
            text-align: center;
            padding: .5rem;
            border-bottom: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &.has-tagline {

                .page-title {

                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 25%;
                        width: 50%;
                        height: 1px;
                        background-color: $clr-neutral-x2light;
                    }

                }

            }

        }

        .page-title {
            font-size: 2rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            margin-top: 0;
            margin-bottom: 0;
        }

        .page-tagline {
            font-family: $font-family-serif;
            width: 75%;
            padding-bottom: 1rem;
            font-style: italic;
            margin: 0 auto;
        }

    }

}