.sidebar-container {
    background: $clr-secondary;
}
.sidebar {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0 auto;
    max-width: 900px;
    font-family: $font-family-sans-serif;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.widget_search {
    float: left;
}
.tax-scripture .sidebar,
.post-type-archive-aslv-passage .sidebar
{
    padding-top: 3rem;
    padding-bottom: 3rem;
    .widget_search {
        float: right;
        margin-right: -2rem;
    }
}

.search-form {

    margin-right: -1rem;

    .search-field {
        border: none;
        background-color: white;
        font-size: 17px;
        border-radius: 2rem;
        color: $clr-secondary;
        //font-family: $font-family-sans-serif;
        font-weight: 300;
        line-height: 1;
        padding: 0;
        height: auto;
        padding-top: .6rem;
        padding-bottom: .4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        &::-webkit-input-placeholder { /* Chrome */
            color: $clr-secondary;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $clr-secondary;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $clr-secondary;
            opacity: 1;
        }
        &:-moz-placeholder { /* Firefox 4 - 18 */
            color: $clr-secondary;
            opacity: 1;
        }
    }

    .search-submit {
        background: transparent url('../images/icn-search.svg') no-repeat center center;
        background-size: auto 75%;
        width: 4rem;
        position: relative;
        left: -4.5rem;
        border: none;
        padding: 0;
        text-indent: -999rem;
        &:hover,
        &:active,
        &:focus
        {
            background-color: transparent;
        }
    }

}

.widget_tag_cloud {
    float: left;
    h3 {
        display: none;
    }
    padding-top: 1px;
    a {
        display: inline-block;
        font-size: small !important;
        line-height: 2;
        border: 1px solid white;
        border-radius: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        margin-right: .5rem;
        &:active,
        &:hover
        {
            color: white;
            text-decoration: none;
            background-color: $clr-secondary-dark;
        }
    }
}