.type-podcast {
    position: relative;
    header {
        margin-bottom: 2.5%;
    }
    br {
        display: block;
        content: "";
        margin-top: 1.5rem;
    }
}

.podcast_player {
    position: absolute;
    top: 7rem;
    left: 0;
    width: 100%;
    padding-top: 40%;
    margin-top: -40%;
}

.podcast_meta {
    display: none;
}

.wp-video {
    width: 100% !important;
    video {
        width: 100% !important;
    }
}

.post-meta {
    position: relative;
    z-index: 5;
    margin-top: 65%;
    //top: 51.5rem;
    text-align: center;
    color: $clr-secondary;
    font-style: italic;
    a {
        color: $clr-secondary;
        text-decoration: underline;
    }
    .updated-long {
        display: none;
    }
}
@media (min-width: 480px) {
    .post-meta {
        margin-top: 60%;
        .updated-long {
            display: inline;
        }
        .updated-short {
            display: none;
        }
    }
}

.post-prayer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid $clr-secondary;
    border-bottom: 1px solid $clr-secondary;
    font-style: italic;
}