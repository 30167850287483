/* footer */
.content-info {

    clear: both;
    background: $clr-neutral-medium url('../images/icn-brand.svg') no-repeat center 15px;
    background-size: 45px 45px;
    color: $clr-neutral-x2light;
    font-size: $font-size-small;
    font-style: italic;
    text-align: center;
    padding-top: 75px;

    .widget {
        padding-bottom: 30px;
    }

    h3 {
        font-family: $font-family-sans-serif;
        color: $clr-secondary;
        font-size: $font-size-base;
        font-weight: 100;
        font-style: normal;
        margin-top: 0;
        margin-bottom: 0;
    }

    a {
        color: $clr-neutral-x2light;
        text-decoration: underline;
    }

}

/* social */
#menu-social {

    background-color: $clr-secondary;
    height: 100px;
    padding-top: 30px;

    .menu-item {

        display: inline-block;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $clr-neutral-x2light;
        margin-left: 4px;
        margin-right: 4px;

        &.menu-twitter {
            padding-left: 2px;
        }

        a {

            padding: 0;
            padding-top: 8px;

            .fa {
                color: $clr-secondary;
                font-size: 24px;
            }

            &:hover {
                background-color: transparent;
                border-radius: 50%;
            }

            .fontawesome-text {
                position: absolute;
                left: -999em;
            }

        }
    }
}